'use client';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import ResponsiveImage from '../../elements/images/ResponsiveImage/ResponsiveImage';
import { ScrollSlider02Type } from '../ScrollSlider02/ScrollSlider02.type';
import styles from './ScrollSlider02SP.module.scss';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";


// Swiperの必要なモジュールを追加
SwiperCore.use([Navigation, Pagination, Autoplay]);

const ScrollSlider02SP = (props: ScrollSlider02Type) => {
  const { scrollSlider02 } = props;
  return (
    <div className={`scrollSlider-section ${styles.scrollSlider}`}>
      <div className={styles.scrollSlider__image}>
        <div className="scrollSlider__image__swiper">
          <Swiper
            loop={true} // ループする設定
            autoplay={{
              delay: 0, // 自動再生の待機時間を0に設定
              disableOnInteraction: false, // ユーザーの操作後も自動再生を続ける
              reverseDirection: true,
            }}
            slidesPerView={1.5} // 画面に表示するスライドの数を設定
            spaceBetween={10} // スライド間のスペースを調整
            modules={[Navigation, Pagination]}
            pagination={false} // ページネーションを有効化
            effect="slide" // スクロールエフェクトを指定
            speed={5000} // スライドの速度を設定 (ミリ秒)
            freeMode={true} // 自由なモードでスライドを動かす設定
          >
            {scrollSlider02.scrollSliderItems.map((item, index) => (
              <SwiperSlide key={index} suppressHydrationWarning>
                <div className={styles.imageContainer}>
                  <ResponsiveImage
                    srcPC={item.scrollSliderItem.srcPC}
                    srcSP={item.scrollSliderItem.srcSP}
                    altText={item.scrollSliderItem.altText}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {/* <div className={styles.scrollSlider__bgText}>GALLERY</div> */}
      </div>
    </div>
  );
};

export default ScrollSlider02SP;