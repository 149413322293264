'use client';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import styles from './ScrollSlider.module.scss';
import { ScrollSliderType } from './ScrollSlider.type';

import ResponsiveImage from '@/components/elements/images/ResponsiveImage/ResponsiveImage';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";


// Swiperの必要なモジュールを追加
SwiperCore.use([Navigation, Pagination, Autoplay]);

const ScrollSlider = (props: ScrollSliderType) => {
  const { scrollSlider } = props;
  return (
    <div className={`scrollSlider-section ${styles.scrollSlider}`}>
      <div className={styles.scrollSlider__image}>
        <ul className="scrollSlider__image__swiper">
          
          <Swiper
            loop={true} // ループする設定
            autoplay={{
              delay: 0, // 自動再生の待機時間を0に設定
              disableOnInteraction: false, // ユーザーの操作後も自動再生を続ける
            }}
            slidesPerView={3.5} // 画面に表示するスライドの数を設定
            // breakpoints={{
            //   // スライドの表示枚数：721px以上の場合
            //   721: {
            //     slidesPerView: 3.5,
            //   }}
            // }
            centeredSlides={true}
            spaceBetween={10} // スライド間のスペースを調整
            modules={[Navigation, Pagination]}
            pagination={false} // ページネーションを有効化
            effect="slide" // スクロールエフェクトを指定
            speed={5000} // スライドの速度を設定 (ミリ秒)
            freeMode={true} // 自由なモードでスライドを動かす設定
          >
            
            {scrollSlider.scrollSliderItems.map((item, key) => (
              <SwiperSlide key={key} suppressHydrationWarning>
                <li className={styles.imageContainer}>
                  <ResponsiveImage
                    srcPC={item.scrollSliderItem.srcPC}
                    srcSP={item.scrollSliderItem.srcSP}
                    altText={item.scrollSliderItem.altText}
                  />
                </li>
              </SwiperSlide>
            ))}
          </Swiper>
          
        </ul>
      </div>
    </div>
  );
};

export default ScrollSlider;