/**
 * HTMLタグを取り除く関数
 * @param htmlString HTMLタグを含む文字列
 * @returns プレーンテキスト
 */
const getStripHtmlTagsText = (htmlString: string): string  => {
  // ブラウザの仮想DOMを使用して、HTMLをパースしてからテキストを取得する
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;
  return tempDiv.textContent || tempDiv.innerText || '';
}

export default getStripHtmlTagsText;