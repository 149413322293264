'use client';

import getFormatedDate from "@/utils/getFormatedDate";
import getStripHtmlTagsText from "@/utils/getStripHtmlTagsText";
import { Suspense } from "react";
import styles from "./BlogArchiveBox.module.scss";
import { BlogArchiveBoxType } from "./BlogArchiveBox.types";

const BlogArchiveBox = (props: BlogArchiveBoxType) => {
  const {articleItems, slug, label} = props;

  return (
    <Suspense>
      {articleItems.length >= 1 ? (
        <div className={styles['shop-blogs']}>
          {articleItems.map((item:any, key:number) => (
              <article key={key} className={styles['shop-blogs-item']}>
              <a key={key} href={`${slug}post/?id=${item.id}`}>
                <div className={styles['shop-blogs-item__eyecatch-wrapper']}>
                  {item.eyecatch ? (
                    <img className={styles['shop-blogs-item__eyecatch']} src={`${item.eyecatch.url}?fm=webp&w=750`} alt={item.title} />
                  ) : (
                    <img className={styles['shop-blogs-item__eyecatch']} src={'/images/noimg.png'} alt={item.title} />
                  )}
                </div>
              
                <div className={styles['shop-blogs-item__info-wrapper']}>
                  <div className={styles['shop-blogs-item__date']}>{getFormatedDate(item.publishedAt)}<span className={styles['shop-blogs-item__label']}>{label}</span></div>
                  <div className={styles['shop-blogs-item__title']}>{item.title}</div>
                  <div className={styles['shop-blogs-item__short-content']}>{getStripHtmlTagsText(item.content)}</div>
                </div>
                
              </a>
          </article>
          ))}
        </div>
      ) : 
        <>
          <p>ただいま準備中です</p>
        </>
      }
    </Suspense>
  )
}

export default BlogArchiveBox;