import { StaffType } from "../_types/Staff.types";

export const StaffContext: StaffType = {
  staffItems: {
    "shirakihara": {
      nameJP: "白木原　拓哉",
      nameEN: "TAKUYA<br>SHIRAKIHARA",
      imageItem: {
        srcPC: "/images/staff/shirakihara.png",
        srcSP: "/images/staff/shirakihara.png",
        altText: "白木原　拓哉"
      },
      staffInfo: [
        {
          index: "出身地",
          content: "田川市",
        },
        {
          index: "誕生日",
          content: "2月16日"
        },
        {
          index: "趣味",
          content: "野球"
        },
        {
          index: "一言",
          content: "『一鍼入魂』"
        }
      ]
    },
    "hashiguchi": {
      nameJP: "橋口　拓人",
      nameEN: "TAKUTO<br>HASHIGUCHI",
      imageItem: {
        srcPC: "/images/staff/hashiguchi.png",
        srcSP: "/images/staff/hashiguchi.png",
        altText: "橋口　拓人",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "福岡県直方市",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "スニーカー・カラオケ"
        },
        {
          index: "一言",
          content: "延岡の美味しいご飯屋さん教えて下さい。"
        }
      ]
    },
    "uchida": {
      nameJP: "内田　貴久",
      nameEN: "TAKAHISA<br>UCHIDA",
      imageItem: {
        srcPC: "/images/staff/uchida.png",
        srcSP: "/images/staff/uchida.png",
        altText: "内田　貴久",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "福岡県直方市",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "ドライブ、バイク"
        },
        {
          index: "一言",
          content: "痛みの９割は姿勢で治ります"
        }
      ]
    },
    "kurihara": {
      nameJP: "栗原　明宏",
      nameEN: "AKIHIRO<br>KURIHARA",
      imageItem: {
        srcPC: "/images/staff/kurihara.png",
        srcSP: "/images/staff/kurihara.png",
        altText: "栗原　明宏",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "熊本県熊本市",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "バスケット・読書・映画鑑賞"
        },
        {
          index: "一言",
          content: "人事を尽くして、天命を待つ"
        }
      ]
    },
    "takami": {
      nameJP: "高見　真由",
      nameEN: "MAYU<br>TAKAMI",
      imageItem: {
        srcPC: "/images/staff/takami.png",
        srcSP: "/images/staff/takami.png",
        altText: "高見　真由",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "熊本県合志市",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "温泉・旅行"
        },
        {
          index: "一言",
          content: "産後・女性のお悩みはお任せ下さい！"
        }
      ]
    },
    "iwanaga": {
      nameJP: "岩永　太一",
      nameEN: "TAICHI<br>IWANAGA",
      imageItem: {
        srcPC: "/images/staff/iwanaga.png",
        srcSP: "/images/staff/iwanaga.png",
        altText: "岩永　太一",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "熊本県大都町",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "ツーリング・夜景を見に行く"
        },
        {
          index: "一言",
          content: "常に笑顔"
        }
      ]
    },
    "nakayama": {
      nameJP: "中山　美優",
      nameEN: "MIYU<br>NAKAYAMA",
      imageItem: {
        srcPC: "/images/staff/nakayama.png",
        srcSP: "/images/staff/nakayama.png",
        altText: "中山　美優",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "福岡県糟屋郡",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "自然めぐり"
        },
        {
          index: "一言",
          content: "大豆の入ったご飯が大好きです。"
        }
      ]
    },
    "furuta": {
      nameJP: "古田　亮介",
      nameEN: "RYOUSUKE<br>FURUTA",
      imageItem: {
        srcPC: "/images/staff/furuta.png",
        srcSP: "/images/staff/furuta.png",
        altText: "中山　美優",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "鹿児島",
        },
        {
          index: "誕生日",
          content: "1月20日"
        },
        {
          index: "趣味",
          content: "旅行、ランニング、温泉"
        },
        {
          index: "一言",
          content: "人は思った通りの人間になる"
        }
      ]
    },
    "yumi": {
      nameJP: "弓　奈津美",
      nameEN: "NATSUMI<br>YUMI",
      imageItem: {
        srcPC: "/images/staff/yumi.png",
        srcSP: "/images/staff/yumi.png",
        altText: "弓　奈津美",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "福岡県 久留米市",
        },
        {
          index: "誕生日",
          content: "7月9日"
        },
        {
          index: "趣味",
          content: "バスケ、旅行、映画鑑賞"
        },
        {
          index: "一言",
          content: "油断禁物"
        }
      ]
    },
    "suzuki": {
      nameJP: "鈴木　悠",
      nameEN: "HARUKA<br>SUZUKI",
      imageItem: {
        srcPC: "/images/staff/suzuki.png",
        srcSP: "/images/staff/suzuki.png",
        altText: "鈴木　悠",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "東京都八王子市",
        },
        {
          index: "誕生日",
          content: "7月12日"
        },
        {
          index: "趣味",
          content: "ドライブ、食べ放題"
        },
        {
          index: "一言",
          content: "因果応報"
        }
      ]
    },
    "hiraki": {
      nameJP: "平木　一圭",
      nameEN: "IKKEI<br>HIRAKI",
      imageItem: {
        srcPC: "/images/staff/hiraki.png",
        srcSP: "/images/staff/hiraki.png",
        altText: "平木　一圭",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "熊本県",
        },
        {
          index: "誕生日",
          content: "5月3日"
        },
        {
          index: "趣味",
          content: "ゲーム"
        },
        {
          index: "一言",
          content: "理想を語れなくなったら人間の進化は止まる！！"
        }
      ]
    },
    "yokota": {
      nameJP: "横田　健一郎",
      nameEN: "KENICHIRO<br>YOKOTA",
      imageItem: {
        srcPC: "/images/staff/yokota.png",
        srcSP: "/images/staff/yokota.png",
        altText: "横田　健一郎",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "福岡県 志免町",
        },
        {
          index: "誕生日",
          content: "2月2日"
        },
        {
          index: "趣味",
          content: "バイク、車"
        },
        {
          index: "一言",
          content: "バイクで日本一周 いつか…"
        }
      ]
    },
    "yokoo": {
      nameJP: "横尾　有哉",
      nameEN: "YUYA<br>YOKOO",
      imageItem: {
        srcPC: "/images/staff/yokoo.png",
        srcSP: "/images/staff/yokoo.png",
        altText: "横尾　有哉",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "佐賀県佐賀市",
        },
        {
          index: "誕生日",
          content: "-"
        },
        {
          index: "趣味",
          content: "バスケット、スニーカー"
        },
        {
          index: "一言",
          content: "『結果で示す』 いつか…"
        }
      ]
    },
    "yasuda": {
      nameJP: "安田　彬乃",
      nameEN: "AKINO<br>YASUDA",
      imageItem: {
        srcPC: "/images/staff/yasuda.png",
        srcSP: "/images/staff/yasuda.png",
        altText: "安田　彬乃",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "山口県防府市",
        },
        {
          index: "誕生日",
          content: "-"
        },
        {
          index: "趣味",
          content: "カフェ巡り、森林浴"
        },
        {
          index: "一言",
          content: "清廉潔白"
        }
      ]
    },
    "hirota": {
      nameJP: "廣田　空也",
      nameEN: "KOUYA<br>HIROTA",
      imageItem: {
        srcPC: "/images/staff/hirota.png",
        srcSP: "/images/staff/hirota.png",
        altText: "廣田　空也",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "大分県玖珠郡",
        },
        {
          index: "誕生日",
          content: "-"
        },
        {
          index: "趣味",
          content: "洋楽・ジブリ"
        },
        {
          index: "一言",
          content: "笑う門には福来たる"
        }
      ]
    },
    "kouno": {
      nameJP: "河野　翔太郎",
      nameEN: "SHOTARO<br>KOUNO",
      imageItem: {
        srcPC: "/images/staff/kouno.png",
        srcSP: "/images/staff/kouno.png",
        altText: "河野　翔太郎",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "大分県豊後高田市",
        },
        {
          index: "誕生日",
          content: "-"
        },
        {
          index: "趣味",
          content: "旅行・野球"
        },
        {
          index: "一言",
          content: "人生なんとかなる"
        }
      ]
    },
    "kirino": {
      nameJP: "桐野　祥汰",
      nameEN: "SHOTA<br>KIRINO",
      imageItem: {
        srcPC: "/images/staff/kirino.png",
        srcSP: "/images/staff/kirino.png",
        altText: "桐野　祥汰",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "-",
        },
        {
          index: "誕生日",
          content: "-"
        },
        {
          index: "趣味",
          content: "カフェ巡り　ギター"
        },
        {
          index: "一言",
          content: "なるようになる"
        }
      ]
    },
    "murakami": {
      nameJP: "村上　恵美",
      nameEN: "MEGUMI　MURAKAMI",
      imageItem: {
        srcPC: "/images/staff/murakami.png",
        srcSP: "/images/staff/murakami.png",
        altText: "村上　恵美",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "糟屋郡宇美町",
        },
        {
          index: "誕生日",
          content: "7月2日"
        },
        {
          index: "趣味",
          content: "柔道・読書"
        },
        {
          index: "一言",
          content: "『過去は変えられないけど、過去の意味は変えられる』"
        }
      ]
    },
    "yamabe": {
      nameJP: "山部　竜矢",
      nameEN: "TATSUYA<br>YAMABE",
      imageItem: {
        srcPC: "/images/staff/yamabe.png",
        srcSP: "/images/staff/yamabe.png",
        altText: "山部　竜矢",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "熊本県",
        },
        {
          index: "誕生日",
          content: "4月20日"
        },
        {
          index: "趣味",
          content: "野球"
        },
        {
          index: "一言",
          content: "『今あるものでベストを尽くす』"
        }
      ]
    },
    "hamasu": {
      nameJP: "濱洲　茂治",
      nameEN: "SHIGEHARU<br>HAMASU",
      imageItem: {
        srcPC: "/images/staff/hamasu.png",
        srcSP: "/images/staff/hamasu.png",
        altText: "濱洲　茂治",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "須恵町",
        },
        {
          index: "誕生日",
          content: "6月6日"
        },
        {
          index: "趣味",
          content: "野球、ゴルフ、バドミントン"
        },
        {
          index: "一言",
          content: "『勝ちに不思議な勝ちあり、負けに不思議な負けなし。』"
        }
      ]
    },
    "nikawa": {
      nameJP: "二川　春",
      nameEN: "HARU<br>NIKAWA",
      imageItem: {
        srcPC: "/images/staff/nikawa.png",
        srcSP: "/images/staff/nikawa.png",
        altText: "二川　春",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "大阪府吹田市",
        },
        {
          index: "誕生日",
          content: "2月24日"
        },
        {
          index: "趣味",
          content: "サイクリング、ピクニック"
        },
        {
          index: "一言",
          content: "『寝る子は育つ』"
        }
      ]
    },
    "matsumoto": {
      nameJP: "松本　公希",
      nameEN: "KOUKI<br>MATSUMOTO",
      imageItem: {
        srcPC: "/images/staff/matsumoto.png",
        srcSP: "/images/staff/matsumoto.png",
        altText: "松本　公希",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "宮崎県新富町",
        },
        {
          index: "誕生日",
          content: "1月2日"
        },
        {
          index: "趣味",
          content: "映画鑑賞"
        },
        {
          index: "一言",
          content: "『反省より改善』"
        }
      ]
    },
    "okumura": {
      nameJP: "奥村　啓太",
      nameEN: "KEITA<br>OKUMURA",
      imageItem: {
        srcPC: "/images/staff/okumura.png",
        srcSP: "/images/staff/okumura.png",
        altText: "奥村　啓太",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "長崎県雲仙市",
        },
        {
          index: "誕生日",
          content: "6月24日"
        },
        {
          index: "趣味",
          content: "バレーボール、映画"
        },
        {
          index: "一言",
          content: "『夢見ることができればそれは実現できる』"
        }
      ]
    },
    "oomori": {
      nameJP: "大森　好美",
      nameEN: "YOSHIMI<br>OOMORI",
      imageItem: {
        srcPC: "/images/staff/oomori.png",
        srcSP: "/images/staff/oomori.png",
        altText: "大森　好美",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "ー",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "ー"
        },
        {
          index: "一言",
          content: "ー"
        }
      ]
    },
    "machidori": {
      nameJP: "待鳥　真理子",
      nameEN: "MARIKO<br>MACHIDORI",
      imageItem: {
        srcPC: "/images/staff/machidori.png",
        srcSP: "/images/staff/machidori.png",
        altText: "待鳥　真理子",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "ー",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "ー"
        },
        {
          index: "一言",
          content: "ー"
        }
      ]
    },
    "saeki": {
      nameJP: "佐伯　綾香",
      nameEN: "AYAKA<br>SAEKI",
      imageItem: {
        srcPC: "/images/staff/saeki.png",
        srcSP: "/images/staff/saeki.png",
        altText: "佐伯　綾香",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "ー",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "ー"
        },
        {
          index: "一言",
          content: "ー"
        }
      ]
    },
    "akagi": {
      nameJP: "赤木　美保",
      nameEN: "MIHO<br>AKAGI",
      imageItem: {
        srcPC: "/images/staff/akagi.png",
        srcSP: "/images/staff/akagi.png",
        altText: "赤木　美保",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "島根県出雲市",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "ハンマー投げ"
        },
        {
          index: "一言",
          content: "ー"
        }
      ]
    },
    "yoshimatsu": {
      nameJP: "吉松　大地",
      nameEN: "DAICHI<br>YOSHIMATSU",
      imageItem: {
        srcPC: "/images/staff/yoshimatsu.png",
        srcSP: "/images/staff/yoshimatsu.png",
        altText: "吉松　大地",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "滋賀県",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "ー"
        },
        {
          index: "一言",
          content: "関西(滋賀)出身なので、話すことが好きです。<br>院内では自然と大きい声で話してしまうこともありますが気にしないでください♪<br>話すのも好きですが、患者さんの話はしっかりと受け止めますので、何でも相談してください♪"
        }
      ]
    },
    "hirata": {
      nameJP: "平田　寛斗",
      nameEN: "KANTO<br>HIRATA",
      imageItem: {
        srcPC: "/images/staff/hirata.png",
        srcSP: "/images/staff/hirata.png",
        altText: "平田　寛斗",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "沖縄県南風原町",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "相撲、弓道"
        },
        {
          index: "一言",
          content: "ー"
        }
      ]
    },
    "nakamura": {
      nameJP: "中村　翔一",
      nameEN: "SHOICHI<br>NAKAMURA",
      imageItem: {
        srcPC: "/images/staff/nakamura.png",
        srcSP: "/images/staff/nakamura.png",
        altText: "中村　翔一",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "佐賀県佐賀市",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "筋トレ"
        },
        {
          index: "一言",
          content: "ー"
        }
      ]
    },
    "yamada": {
      nameJP: "山田　和幸",
      nameEN: "KAZUYUKI<br>YAMADA",
      imageItem: {
        srcPC: "/images/staff/yamada.png",
        srcSP: "/images/staff/yamada.png",
        altText: "山田　和幸",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "福岡県北九州市",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "野球、逆立ち"
        },
        {
          index: "一言",
          content: "ー"
        }
      ]
    },
    "yoshioka": {
      nameJP: "𠮷岡　美陽",
      nameEN: "MIHARU<br>YOSHIOKA",
      imageItem: {
        srcPC: "/images/staff/yoshioka.png",
        srcSP: "/images/staff/yoshioka.png",
        altText: "𠮷岡　美陽",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "宮崎県延岡市",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "ソフトボール、読書"
        },
        {
          index: "一言",
          content: "ー"
        }
      ]
    },
    "tamayose": {
      nameJP: "玉寄　彩香",
      nameEN: "AYAKA<br>TAMAYOSE",
      imageItem: {
        srcPC: "/images/staff/tamayose.png",
        srcSP: "/images/staff/tamayose.png",
        altText: "玉寄　彩香",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "沖縄県",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "旅行、歌うこと"
        },
        {
          index: "一言",
          content: "ー"
        }
      ]
    },
    "miura": {
      nameJP: "三浦　ののか",
      nameEN: "NONOKA<br>MIURA",
      imageItem: {
        srcPC: "/images/staff/miura.png",
        srcSP: "/images/staff/miura.png",
        altText: "三浦　ののか",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "福岡県古賀市",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "ソフトボール、ショッピング、ドライブ"
        },
        {
          index: "一言",
          content: "ー"
        }
      ]
    },
    "wada": {
      nameJP: "和田　輝",
      nameEN: "WADA<br>HIKARU",
      imageItem: {
        srcPC: "/images/staff/wada.png",
        srcSP: "/images/staff/wada.png",
        altText: "和田　輝",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "ー",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "ー"
        },
        {
          index: "一言",
          content: "ー"
        }
      ]
    },
    "kinjo": {
      nameJP: "金城　瑞穂",
      nameEN: "KINJO<br>MIZUHO",
      imageItem: {
        srcPC: "/images/staff/kinjo.png",
        srcSP: "/images/staff/kinjo.png",
        altText: "金城　瑞穂",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "ー",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "ー"
        },
        {
          index: "一言",
          content: "ー"
        }
      ]
    },
    "sato": {
      nameJP: "佐藤　謙太",
      nameEN: "SATOH<br>KENTA",
      imageItem: {
        srcPC: "/images/staff/sato.png",
        srcSP: "/images/staff/sato.png",
        altText: "佐藤　謙太",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "ー",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "ー"
        },
        {
          index: "一言",
          content: "ー"
        }
      ]
    },
    "ochi": {
      nameJP: "越智　龍裕",
      nameEN: "OCHI<br>TATSUHIRO",
      imageItem: {
        srcPC: "/images/staff/ochi.png",
        srcSP: "/images/staff/ochi.png",
        altText: "越智　龍裕",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "ー",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "ー"
        },
        {
          index: "一言",
          content: "ー"
        }
      ]
    },
    "kume": {
      nameJP: "久米　雄大",
      nameEN: "KUME<br>YUDAI",
      imageItem: {
        srcPC: "/images/staff/kume.png",
        srcSP: "/images/staff/kume.png",
        altText: "久米　雄大",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "ー",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "ー"
        },
        {
          index: "一言",
          content: "ー"
        }
      ]
    },
    "segawa": {
      nameJP: "瀬川　留伊",
      nameEN: "SEGAWA<br>RUI",
      imageItem: {
        srcPC: "/images/staff/segawa.png",
        srcSP: "/images/staff/segawa.png",
        altText: "瀬川　留伊",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "ー",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "ー"
        },
        {
          index: "一言",
          content: "ー"
        }
      ]
    },
    "sakashita": {
      nameJP: "坂下　渓",
      nameEN: "SAKASHITA<br>KEI",
      imageItem: {
        srcPC: "/images/staff/sakashita.png",
        srcSP: "/images/staff/sakashita.png",
        altText: "坂下　渓",
      },
      staffInfo: [
        {
          index: "出身地",
          content: "ー",
        },
        {
          index: "誕生日",
          content: "ー"
        },
        {
          index: "趣味",
          content: "ー"
        },
        {
          index: "一言",
          content: "ー"
        }
      ]
    }
  }
};