import styles from "./LayoutSection.module.scss";
import { LayoutSectionType } from "./LayoutSection.types";

const LayoutSection = ({ id, children}: LayoutSectionType) => {
  return (
    <section id={id} className={styles.l_section}>
      {children}
    </section>
  )
}

export default LayoutSection;