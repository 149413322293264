'use client';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import styles from "./ShopStaffCarousel.module.scss";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { StaffContext } from '../../../_settings/_contexts/staffContexts';
import StaffItem from '../StaffItem/StaffItem';

type Props = {
  staffKeys: string[];
}

// Swiperの必要なモジュールを追加
SwiperCore.use([Navigation, Pagination, Autoplay]);

const ShopStaffCarousel = (props: Props) => {
  const { staffKeys } = props
  const staffList = StaffContext.staffItems;

  return (
    <div className={`scrollSlider-section ${styles.scrollSlider}`}>
      <div className={styles.scrollSlider__image}>
        <ul className="scrollSlider__image__swiper">
          
          <Swiper
            loop={true} // ループする設定
            autoplay={{
              delay: 0, // 自動再生の待機時間を0に設定
              disableOnInteraction: false, // ユーザーの操作後も自動再生を続ける
            }}
            slidesPerView={3.5} // 画面に表示するスライドの数を設定
            // breakpoints={{
            //   // スライドの表示枚数：721px以上の場合
            //   721: {
            //     slidesPerView: 3.5,
            //   }}
            // }
            centeredSlides={true}
            spaceBetween={40} // スライド間のスペースを調整
            modules={[Navigation, Pagination]}
            pagination={false} // ページネーションを有効化
            effect="slide" // スクロールエフェクトを指定
            speed={5000} // スライドの速度を設定 (ミリ秒)
            freeMode={true} // 自由なモードでスライドを動かす設定
          >
            
            {/* <div suppressHydrationWarning>
              {staffItems.map((item:StaffItemType, key:number) => (
                <SwiperSlide key={key} suppressHydrationWarning>
                  <StaffItem
                    staffImg={item.staffImg}
                    staffNameEn={item.staffNameEn}
                    staffNameJp={item.staffNameJp}
                    staffInfo={item.staffInfo}
                    key={key}
                  />
                </SwiperSlide>
              ))}
            </div> */}
            <div suppressHydrationWarning>
              {staffKeys.map((key: string, index: number) => {
                const staff = staffList[key];
                if (staff) {
                  return (
                    <SwiperSlide key={key} suppressHydrationWarning>
                      <StaffItem
                        imageItem={staff.imageItem}
                        nameEN={staff.nameEN}
                        nameJP={staff.nameJP}
                        staffInfo={staff.staffInfo}
                        key={key}
                      />
                    </SwiperSlide>
                  )
                }
              })}
            </div>
          </Swiper>
          
        </ul>
      </div>
    </div>
  );
};

export default ShopStaffCarousel;