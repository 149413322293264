'use client';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { StaffContext } from '../../../_settings/_contexts/staffContexts';
import StaffItemSP from '../StaffItemSP/StaffItemSP';
import styles from "./ShopStaffCarouselSP.module.scss";
type Props = {
  staffKeys: string[];
}

// Swiperの必要なモジュールを追加
SwiperCore.use([Navigation, Pagination, Autoplay]);

const ShopStaffCarouselSP = (props: Props) => {
  const { staffKeys } = props
  const staffList = StaffContext.staffItems;

  return (
    <div className={`scrollSlider-section ${styles.scrollSlider} scrollSlider__voice`}>
      <div className={styles.scrollSlider__image}>
        {/* ナビゲーションボタン */}
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>

        <ul className="scrollSlider__image__swiper">
          <Swiper
            loop={true} // ループする設定
            autoplay={false}
            slidesPerView={1.5} // 画面に表示するスライドの数を設定
            // breakpoints={{
            //   721: {
            //     slidesPerView: 3.5,
            //   }
            // }}
            centeredSlides={true}
            spaceBetween={40} // スライド間のスペースを調整
            modules={[Navigation, Pagination]}
            pagination={{ clickable: true }} // ページネーションを有効化
            navigation={{ // ナビゲーションボタンを有効化
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            effect="slide" // スクロールエフェクトを指定
            speed={2000} // スライドの速度を設定 (ミリ秒)
            freeMode={true} // 自由なモードでスライドを動かす設定
          >
            {staffKeys.map((key: string, index: number) => {
                const staff = staffList[key];
                if (staff) {
                  return (
                    <SwiperSlide key={key} suppressHydrationWarning>
                      <StaffItemSP
                        imageItem={staff.imageItem}
                        nameEN={staff.nameEN}
                        nameJP={staff.nameJP}
                        staffInfo={staff.staffInfo}
                        key={key}
                      />
                    </SwiperSlide>
                  )
                }
              })}
          </Swiper>
        </ul>
      </div>
    </div>
  );
};

export default ShopStaffCarouselSP;
