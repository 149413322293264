import { BREAKPOINT_FOR_IMG } from "../../../../constants/breakpoints";
import styles from "./ResponsiveImage.module.scss";
import { ResponsiveImageType } from "./ResponsiveImage.types";

const ResponsiveImage = (props: ResponsiveImageType) => {
  const {srcPC, srcSP, srcWebpPC, srcWebpSP, widthPC, heightPC, widthSP, heightSP, altText, breakpoint} = props;

  const imgBreakPoint = breakpoint ? breakpoint : BREAKPOINT_FOR_IMG;

  return (
    <picture className={styles.responsive_image}>
      {srcWebpPC && <source srcSet={srcWebpPC} media={`(min-width: ${imgBreakPoint}px)`} type="image/webp" />}
      {srcWebpSP && <source srcSet={srcWebpSP} type="image/webp" />}
      <source srcSet={srcPC} media={`(min-width: ${imgBreakPoint}px)`} {...(widthPC && { width: widthPC })} {...(heightPC && { height: heightPC })}　/>
      <img src={srcSP} {...(widthSP && { width: widthSP })} {...(heightSP && { height: heightSP })} alt={altText} />
    </picture>
  )
}

export default ResponsiveImage;