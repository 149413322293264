import ResponsiveImage from "@/components/elements/images/ResponsiveImage/ResponsiveImage";
import parse from "html-react-parser";
import React from "react";
import { StaffInfoType, StaffItemType } from "../../../_settings/_types/Staff.types";
import styles from "./StaffItemSP.module.scss";

const StaffItemSP = (props:StaffItemType) => {
  const {nameJP, nameEN, imageItem, staffInfo} = props;
  return(
    <div className={styles['staff-item']}>
      <div className={styles['staff-item__img']}>
        <ResponsiveImage 
          srcPC={imageItem.srcPC}
          srcSP={imageItem.srcSP}
          altText={imageItem.altText}
        />
        <div className={styles['staff-item__name']}>{parse(nameEN)}</div>
      </div>
      <div className={styles['staff-item__text']}>
        <h3 className={styles['staff-item__text__name']}>{parse(nameJP)}</h3>
        <dl className={styles['staff-item__text__info']}>
          {staffInfo.map((item: StaffInfoType, key: number) => (
            <React.Fragment key={key}>
              <dt key={key}>{parse(item.index)}</dt>
              <dd>{parse(item.content)}</dd>
            </React.Fragment>
          ))}
        </dl>
      </div>
    </div>
  )
}
export default StaffItemSP;