'use client';

import { Suspense } from "react";
import styles from "./VoiceArchiveBox.module.scss";
import { BoxVoiceForShopType } from "./VoiceArchiveBox.types";

const VoiceArchiveBox = (props: BoxVoiceForShopType) => {
  const {articleItems, slug} = props;

  return (
    <Suspense>
      {articleItems.length >= 1 ? (
        <div className={styles['shop-voices']}>
          {articleItems.map((item:any, key:number) => (
            <article key={key} className={styles['shop-voices-item']}>
              <a href={`${slug}post/?id=${item.id}`}>
                {item.eyecatch ? (
                  <div className={styles['shop-voices-item__eyecatch__wrapper']}>
                    <img className={styles['shop-voices-item__eyecatch']} src={`${item.eyecatch.url}?fm=webp`} alt={item.title} />
                  </div>
                ) : (
                  <div className={styles['shop-voices-item__eyecatch__wrapper']}>
                    <img className={styles['shop-voices-item__eyecatch']} src={'/images/noimg_voice.png'} alt={item.title} />
                  </div>
                )}
                <div className={styles['shop-voices-item__properties']}>{item.address}　{item.old}</div>
                <div className={styles['shop-voices-item__title']}>{item.title}</div>
              </a>
            </article>
          ))}
        </div>
      ) : <p>ただいま準備中です</p>}
    </Suspense>
  )
}

export default VoiceArchiveBox;