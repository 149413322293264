'use client';

import LayoutSection from "@/components/layouts/LayoutSection/LayoutSection";
import useFetchContentsForShopPage from "@/hooks/useFetchContentsForShopPage.hooks";
import getFormatedDate from "@/utils/getFormatedDate";
import { Suspense } from "react";
import { Endpoints } from "../../../_settings/_configs/Endpoints";
import { MicroCMSQueryForShopPageType } from "../../../_settings/_templates/QueryForShopPage.types";
import styles from "./SectionNewsForShop.module.scss";

const SectionNewsForShop = (props: MicroCMSQueryForShopPageType) => {
  const {microCMSAuth, endpointName, limit} = props;
  const slug = Endpoints[endpointName].archivePath;

  const {isLoading, response, hasError, errorMessage, label} = useFetchContentsForShopPage(microCMSAuth, endpointName, limit);

  if (isLoading) {
    return (
      <LayoutSection>
        <></>
      </LayoutSection>
    );
  };

  if (hasError) {
    return (
      <LayoutSection>
        <p style={{textAlign: "center"}}>{errorMessage}</p>
      </LayoutSection>
    );
  }

  return (
    <Suspense>
      {response.contents.length != 0 ? (
        <section className={styles['news-sec']}>
          <div className={styles['news-sec__inner']}>
            <div className={styles['news-box']}>
              <div className={styles['news-box-heading']}>
                <h3 className={styles['news-box-heading__sec-title']}>NEWS</h3>
                <div className={styles['news-box-heading__archive-link']}><a href={`${slug}`}>VIEW ALL</a></div>
              </div>
              <div className={styles['news-box-list']}>
                {response.contents.map((item:any, key:number) => (
                  <article className={styles['news-box-list__article']} key={key}>
                    <a href={`${slug}post/?id=${item.id}`} className={styles['news-box-list__article-link']}>
                      <div className={styles['news-box-list__article-info']}>
                        <span className={styles['news-box-list__article-label']}>{label}院</span>
                        <span className={styles['news-box-list__article-date']}>{getFormatedDate(item.publishedAt, 'yyyymmdd')}</span>
                      </div>
                      <div className={styles['news-box-list__article-title']}>{item.title}</div>
                  </a></article>
                ))}
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </Suspense>
  )
}

export default SectionNewsForShop;